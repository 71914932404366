.date-picker {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  max-width: 400px;
  justify-content: space-between; 
  margin-top: 5px;
  /* width: 100%; */
}

.date-picker-field {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
  min-width: 150px;
}

label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

input[type="month"] {
  padding: 10px 12px;
  font-size: 14px;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
  background-color: #fff;
}

input[type="month"]:hover {
  border-color: #004F9F !important;
}

input[type="month"]:focus {
  border-color: #004F9F !important;
  box-shadow: 0 0 4px rgba(0, 86, 179, 0.5);
}

input[type="month"]:disabled {
  background-color: #f0f0f0;
  border-color: #ddd;
  color: #aaa;
  cursor: not-allowed;
}

.date-picker-field label {
  font-family: 'Nunito Sans', sans-serif !important;
}

.date-picker {
  flex-direction: row;
}

.MuiOutlinedInput-root {
  height: 35px !important; 
  min-height: 35px !important; 
  transition: border-color 0.3s ease; 
  font-size: 12px !important;
  font-family: 'Nunito Sans', sans-serif !important;
}

.MuiOutlinedInput-root:hover {
  border-color: #004F9F !important; 
}

.MuiOutlinedInput-root.Mui-focused {
  border-color: #004F9F !important; 
  box-shadow: 0 0 4px rgba(0, 86, 179, 0.5); 
}


.date-picker-text input {
  line-height: normal !important;
}

@media (max-width: 768px) {
  .date-picker {
    padding: 12px;
    border-radius: 6px;
  }

  .date-picker-field {
    flex: 1;
    max-width: calc(50% - 8px); 
  }

  input[type="month"] {
    font-size: 12px;
    padding: 8px 10px;
  }
}
