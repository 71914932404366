@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

* {
  font-family: 'Nunito Sans', sans-serif;
}

.app {
  display: flex;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}


.sidebar {
  width: 193px; 
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  /* height: 100vh; */
  overflow-y: auto;
  transition: width 0.3s ease;
  z-index: 2;
}

.main-content {
  flex: 1;
  transition: margin-left 0.3s ease;
  box-sizing: border-box;
  margin-bottom: 50px;
}

.main-content.with-sidebar {
  margin-left: 193px;
}

.main-content.full-width {
  margin-left: 0;
}

.header {
  /* padding: 10px 20px; */
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-section {
  cursor: pointer;
}

.header-button {
  display: flex;
  align-items: center;
  gap: 15px;
  background: none;
  border: none;
  cursor: pointer;
}

.search-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  outline: none;
}

.icon {
  font-size: 12px;
  color: #555;
}

.user-name {
  font-weight: 600;
  color: #333;
}

.css-133z5cu-MuiDataGrid-root{
  color: #000 !important;
}

/* Large Screens (Desktop) */
@media (min-width: 1024px) {
  /* .dashboard {
    flex-direction: row;
  } */

  .main-content {
    flex: 1;
    margin-left: 193px;
  }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 1023px) {
  /* .dashboard {
    flex-direction: row;
  } */

  .main-content {
    flex: 1;
    margin-left: 193px;
  }
}

/* Small Devices (Mobile) */
@media (max-width: 768px) {
  /* .dashboard {
    flex-direction: column;
    margin-left: 10px;
  } */

  .main-content {
    flex: 1;
    margin-left: 70px;
  }

  .css-133z5cu-MuiDataGrid-root{
    color: #000 !important;
    font-size: 12px !important;
  }
}

@media (max-width: 767px) {
  /* .dashboard {
    flex-direction: column;
    margin-left: 10px;
  } */

  .main-content {
    flex: 1;
    margin-left: 60px;
  }
}

.content-wrapper {
  width: 100vw;
  overflow: hidden;
  position: relative;
}

.security-msg {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  font-size: 14px;
  text-align: center;
  background-color: #f9f9f9;
  padding: 20px;
  box-sizing: border-box;
  color: #fd0000;
}

.app-content {
  flex: 1;
  position: relative;
  z-index: 1;
  background-color: #004F9F !important;
}

.content-inner {
  position: relative;
  z-index: 2;
}

@media (max-width: 768px) {
  .app-content {
    background-color: #004F9F !important;
    /* padding: 30px; */
    /* margin-top: 10px; 
    margin-bottom: 10px;  */
  }
}