.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  width: 170px !important;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: #f8f9fa;
  font-size: 12px !important;
}

.sidebar-content {
  flex: 1; 
  display: flex;
  flex-direction: column;
  gap: 10px; 
}

.eHub-image {
  width: 150px;
  height: auto;
  display: block;
  margin: 0 auto;
  padding-bottom: 10px;
}

.sidebar-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar-button {
  text-decoration: none;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  text-align: left;
  margin-bottom: 10px;
  border-radius: 6px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  padding: 10px;
}

.sidebar-button:hover {
  transform: scale(1.02);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.sidebar-button.selected {
  background-color: #004f9f;
  color: white !important;
}

.sidebar-button.clicked {
  background-color: #069ebd;
  color: white !important;
}

.sidebar-label {
  font-size: 12px;
  margin-left: 8px;
}

.sidebar-label.selected {
  color: #fff !important;
}

.sidebar-icon {
  margin-right: 8px;
}

.version-main-wrapper {
  color: #bfecfd;
  font-size: 8px;
  text-align: center;
  font-weight: 600;
  padding: 6px 0;
  background-color: rgba(0, 123, 255, 0.1);
  line-height: 1.5;
  border-radius: 4px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px !important;
}

@media (max-width: 768px) {
  .sidebar {
    padding: 10px !important;
  }

  .sidebar-button {
    align-items: center;
    padding: 1px 15px;
  }

  .sidebar-label {
    font-size: 12px;
    margin: 10px;
    text-align: center;
  }

  .main-content.with-sidebar {
    margin-left: 0px !important; 
  }

  .main-content.full-width {
    margin-left: 0;
  }

    .eHub-image {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      padding: 5px 10px;
    }
  
    .eHub-image img {
      max-width: 110px;
      height: auto;
    }
  
    .sidebar-collapse-icon {
      color: #fff;
      font-size: 14px;
      cursor: pointer;
    }
  }