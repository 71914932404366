.search-menu-container {
  position: relative;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 2;
}

.search-menu {
  position: fixed;
  top: 65px !important; 
  right: 0;
  width: 30%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10 !important;
  margin-bottom: 200px;
  border-radius: 10px;
  min-width: 310px;
}

.search-menu-header {
  display: flex;
  align-items: center;
  background-color: rgb(220, 220, 220);
  padding: 10px 30px;
  font-size: 20px;
  font-weight: 600;
  border-top-left-radius: 10px; 
  border-top-right-radius: 10px; 
}

.close-button {
  background: none;
  border: none;
  color: red;
  font-size: 18px;
  cursor: pointer;
  transition: transform 0.2s, color 0.2s;
  margin-left: auto;
}

.close-button:hover {
  transform: scale(1.2);
}

.content {
  flex-grow: 1;
  overflow-y: auto;  
  padding-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 60px;
}

.search-menu-content {
  margin-bottom: 15px;
}

.locations-container {
  margin-top: 20px;
}

.locations-title {
  font-weight: bold;
  margin-bottom: 5px;
}

.location-search-container {
  position: relative;
}

.location-search-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  font-size: 14px;
}

.location-search-input:focus {
  outline: none;
  border-color: #007BFF;
}

.dropdown-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 300px;
  overflow-y: auto;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 999;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 5px;
}

.location-item {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.location-item:hover {
  background-color: #f0f0f0;
}

.no-locations-message {
  padding: 8px;
  color: #888;
}

.search-buttons-inline-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;  
  bottom: 0;
  left: 0;
  width: 100%;
  margin-top: 20px;
}

.content::-webkit-scrollbar {
  width: 4px;
}

.content::-webkit-scrollbar-track {
  background: #d9d9d9;
}

.content::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.content::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (max-width: 768px) {
  .search-menu {
    position: fixed;
    top: 0 !important; 
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 3;
    margin-bottom: 200px;
  }
}
