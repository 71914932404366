.search-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .search-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 40%;
    max-height: 70%;
    overflow-y: auto;
    position: relative;
    height: 800px;
  }
  
  .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .popup-heading {
    font-weight: 700;
    margin-right: 10px;
  }
  
  .popup-value {
    color: #333;
  }
  
  .popup-body {
    margin-top: 20px;
  }
  
  .popup-row {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }
  
  .inline {
    display: flex;
  }
  
  .proposal-name {
    margin: 5px;
    font-weight: 700 !important;
    color: #000;
    font-size: 14px;
  
  }
  
  .proposal-val {
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
  }
  
  .anbp-val{
    margin: 5px 5px 5px 15px;
    font-size: 14px;
    font-weight: 700 !important;
    color: #000;
  }

  @media (max-width: 768px) {
    .search-popup-content {
      width: 100% !important;
    }
  }