.search-bar-container {
  display: flex;
  align-items: flex-start;
  border-radius: 5px;
  padding: 5px;
  width: 300px;
  background-color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
}

.search-input{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
