.table-wrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
  /* margin-top: 10px; */
}

.custom-toolbar {
  font-size: 12px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.left-toolbar {
  display: flex;
  gap: 16px;
}

.custom-toolbar .MuiToolbar-root {
  display: flex;
  align-items: center;
}

.MuiDataGrid-row {
  cursor: default; 
}

.MuiDataGrid-row.clickable {
  cursor: pointer; 
}

@media (max-width: 768px) {
  .custom-toolbar {
    flex-direction: column; 
    gap: 0px;
  }

  .left-toolbar {
    flex-direction: row; 
    gap: 8px; 
  }

  .custom-toolbar .MuiToolbar-root {
    width: 100%; 
  }
}

.table-wrapper.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1300;
  background: white;
}

.fullscreen-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  color: #1976d2;
  font-size: 12px !important;
}

.policy-table-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 5px;
}

.policy-table-bold {
  font-weight: bold;
}

.policy-table-label {
  width: 40%;
}

.policy-table-no-label {
  width: auto;
}