.role-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .role-title {
    font-size: 14px;
    color: #004F9F;
    margin-left: 10px;
    text-align: left !important;
    font-weight: 600;
  }
  
  .roles {
    display: flex;
    gap: 5px;
    overflow-x: auto;
    padding-bottom: 10px; 
    max-width: 100%; 
    scrollbar-width: thin;
  }
  
  .roles::-webkit-scrollbar {
    height: 8px; 
  }
  
  .roles::-webkit-scrollbar-track {
    background-color: #d9d9d9;
  }
  
  .roles::-webkit-scrollbar-thumb {
    background-color: #e1edfa; 
    border-radius: 10px; 
  }
  
  .role-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease;
    min-width: 70px; 
    border-radius: 10px;
    padding-top: 10px !important;
  }
  
  .role-item:hover {
    transform: scale(1.02);
  }
  
  .role-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: lightgray;
  }
  

  .role-name {
    margin-top: 0.5rem;
    font-size: 12px;
    color: #333;
    text-align: center;
  }
  
  .role-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s ease;
    min-width: 70px;
  }
  
  .role-item:hover {
    transform: scale(1.02);
  }
  
  /* .role-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: lightgray;
  } */

  .role-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  
  .role-name {
    margin-top: 0.5rem;
    font-size: 12px;
    color: #333;
    text-align: center;
  }
    
  .role-item:hover {
    background-color: #f1efef;
    transform: scale(1.05);
  }
  
  .role-item.active {
    background-color: #f2ecec;
    transform: scale(0.98); 
  }
  