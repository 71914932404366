.header {
  padding-left: 10px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-right: 50px !important;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s;
}

.header-path {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  cursor: default !important;
}

.logout-button{
  border-radius: 6px;
  padding: 8px 10px !important;
}

.logout-button:hover {
  transition: transform 0.3s ease;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.level {
  font-size: 0.8rem; 
  color: #666; 
  line-height: 1.2;
}

.username {
  font-weight: 600;
  color: #333;
  font-size: 0.8rem; 
  line-height: 1.2;
}


.header:hover {
  background-color: #f8f8f8; 
}

/* .toggle-section {
  cursor: pointer;
  margin-left: 20px; 
} */

.toggle-section {
  display: flex;
  align-items: center; 
  gap: 10px; 
}

.toggle{
  margin-left: 30px;
  cursor: pointer;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 10px; 
}

.header-button {
  background: none;
  border: none;
  cursor: pointer;
}

.logout-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 12px; 
  padding: 0; 
}

.header-icon {
  font-size: 1.5rem;
  color: #555;
}

.user-icon {
  display: flex;
  align-items: center;
}

.user-image {
  width: 30px;
  height: 30px; 
  border-radius: 50%; 
  margin-right: 10px; 
}

.code {
  font-weight: 600;
  color: #333;
  font-size: 12px; 
  line-height: 1.2; 
}

.custom-menu {
  position: absolute;
  top: 100%;
  right: 100px;
  background: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  z-index: 1000;
  width: 180px;
  display: flex;
  flex-direction: column;
}

.menu-item {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  transition: background-color 0.3s;
}

.menu-item:hover {
  background-color: #f5f5f5;
}

.menu-icon {
  margin-right: 10px;
  font-size: 18px;
  color: #555;
}

.divider {
  height: 1px;
  background: #ddd;
  margin: 5px 0;
  
}


.divider{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

@media (min-width: 768px) {
  .header {
    padding: 10px;
  }
  .search-input {
    width: 200px; 
  }
  .eHub-header-image {
    display: none;
  }
}

@media (max-width: 767px) {
  .header {
    flex-direction: row;
    padding-left: 2px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    padding-right: 5px !important;
  }

  .header-button {
    gap: 8px;
  }

  .icon {
    font-size: 12px;
  }

  .user-info {
    display: none;
  }

  .header-path {
    display: none;
  }

  .logout-button{
    border-radius: 6px;
    padding: 6px 8px !important;
    font-size: 12px;
  }

     .custom-menu {
       right: 10px;
     }
  .eHub-header-image {
    max-width: 60px;
    height: auto;
  }

  .toggle{
    margin-left: 10px;
  }
}