.primary-button {
    color: #fff;
    border: none;
    padding: 4px 5px;
    /* padding: 8px 10px; */
    /* margin-right: 5px; */
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-style: 12px;
  }
  
  .primary-button .button-icon {
    margin-right: 8px !important; 
  }
  
  .primary-button:hover {
    transform: scale(1.05); 
  }
  
  .primary-button:active {
    transform: scale(0.98); 
  }
  
  .primary-button:focus {
    outline: none;
  }

    .primary-button.disabled,
    .primary-button:disabled {
      background-color: #A9A9A9;
      cursor: not-allowed;
      transform: none;
    }
  
    @media (max-width: 768px) {
      .primary-button {
        padding: 6px 8px; 
      }
    }