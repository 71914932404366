.sidebar{
  background-color: #00194f !important;
}

.sidebar-button.selected {
  background-color: #069ebd;
  color: white !important;
}

.sidebar-button.clicked {
  background-color: #069ebd;
  color: white !important;
}

.sidebar-label {
  color: #fff;
}

.sidebar-button {
  color: #fff;
}

.main-content {
  background-color: #fff;
}

.card, .proposal-card, .proposal-card, .performance-container{
  background-color: #fff !important;
}

.logout-button {
  background-color: #fd0000 !important;
  color: #fff;
}

.logout-button:hover {
  background-color: #de0202 !important;
  color: #fff;
}

.n{
  background-color: #004f9f;
  background-color: #21618c;
  background-color: #2980b9;
  background-color: #71BEE7;
  background-color: #6bb7e4;
  background-color: #71BEE7;

  background-color: #fd0000; 
  background-color: #ff6666;
  background-color: #EF9198;
  background-color: #eaf7ff;

}